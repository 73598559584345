import { __decorate } from "tslib";
import { Component, Vue, Watch } from 'vue-property-decorator';
import { asaasConfiguracaoStore, mainStore } from '@/store';
let AsaasConfiguracaoComponent = class AsaasConfiguracaoComponent extends Vue {
    constructor() {
        super(...arguments);
        this.loadingSaving = false;
        this.asaasConfiguracao = {
            receber_cartao_credito: false,
            receber_pix: false,
            pix_taxa_repassada: false,
            cartao_taxa_repassada: false,
            permite_recorrencia_cartao_credito: false,
        };
    }
    onchangeReceberCartaoCredito(val) {
        if (!val) {
            this.asaasConfiguracao.cartao_taxa_repassada = false;
        }
    }
    onchangeReceberPIX(val) {
        if (!val) {
            this.asaasConfiguracao.pix_taxa_repassada = false;
        }
    }
    get currentUser() {
        return mainStore.userProfile;
    }
    async mounted() {
        const response = await asaasConfiguracaoStore.getAsaasConfiguracao();
        if (response) {
            this.asaasConfiguracao = {
                receber_cartao_credito: response.receber_cartao_credito,
                receber_pix: response.receber_pix,
                pix_taxa_repassada: response.pix_taxa_repassada,
                cartao_taxa_repassada: response.cartao_taxa_repassada,
                permite_recorrencia_cartao_credito: response.permite_recorrencia_cartao_credito,
            };
        }
        else {
            this.asaasConfiguracao = {
                receber_cartao_credito: true,
                receber_pix: true,
                pix_taxa_repassada: false,
                cartao_taxa_repassada: true,
                permite_recorrencia_cartao_credito: false,
            };
        }
    }
    async updatePreferences() {
        this.loadingSaving = true;
        await asaasConfiguracaoStore.updateAsaasConfiguracao(this.asaasConfiguracao);
        this.loadingSaving = false;
    }
};
__decorate([
    Watch('asaasConfiguracao.receber_cartao_credito')
], AsaasConfiguracaoComponent.prototype, "onchangeReceberCartaoCredito", null);
__decorate([
    Watch('asaasConfiguracao.receber_pix')
], AsaasConfiguracaoComponent.prototype, "onchangeReceberPIX", null);
AsaasConfiguracaoComponent = __decorate([
    Component
], AsaasConfiguracaoComponent);
export default AsaasConfiguracaoComponent;
